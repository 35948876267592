import * as s from './styles/sobre.module.scss';
import Layout from '../components/layout';
import monografia from '../assets/static/monografia.pdf';
import Paper from '../components/paper';
import React, { ReactElement } from 'react';

export default function Sobre(): ReactElement {
  return (
    <Layout title="Sobre" gray>
      <Paper title="Sobre a SysRS">
        <p>
          Ter um Sistema de Gestão <b>seguro e bem fundamentado</b> é um diferencial para qualquer
          empresa que busca competir no mercado. É partindo dessa convicção que a SysRS desenvolve
          soluções para seus clientes, trazendo <b>facilidade e agilidade</b> para suas tarefas, de
          forma precisa e segura.
        </p>
        <p>
          Fundada em 2006, a SysRS nasceu com base nas experiências acumuladas de seu sócio fundador, que <b>desde
            1998 atuava no mercado de TI</b>. O ponto de partida para a desenvolvimento do MR1 deu-se em 2004, apartir
          da <a href={monografia}>Monografia para Conclusão do Curso de Ciência da
            Computação</a>, que é a base da arquitetura sobre a qual o sistema foi construído.
        </p>
        <p>
          Utilizando <b>tecnologia de ponta</b> no desenvolvimento de software, tem em seu quadro de colaboradores
          técnicos especializados, e com grande experiência em análise, desenvolvimento e implantação de sistemas ERP,
          em empresas pequeno, médio e grande porte. A SysRS é uma empresa sólida que prima pela <b>excelência na
            qualidade</b> de seus produtos e serviços, e está constantemente aprimorando e evoluindo seus produtos,
          sempre visando a total <b>satisfação dos seus clientes</b>.
        </p>
        <p>
          Atuando nas áreas Desenvolvimento de Software e Consultoria em Implantação de Sistemas ERP, a SysRS vem se
          destacando no mercado como uma empresa <b>séria e responsável</b>, que oferece aos seus clientes muito mais
          que sistemas de informação, mas sim, soluções completas que contribuem para o seu crescimento, tornando-as
          mais competitivas no mercado global.
        </p>
        <h2 className="topic">
          <b>
            Missão
          </b>
        </h2>
        <p>
          Fornecer produtos e serviços de qualidade, contribuindo para que os clientes e colaboradores atinjam seus
          objetivos.
        </p>

        <h2 className="topic">
          <b>
            Visão
          </b>
        </h2>
        <p>
          Ser referência no Mercado de Software para empresas de pequeno e médio porte, desenvolvendo soluções que
          superem as expectativas dos nossos clientes.
        </p>

        <h2 className="topic">
          <b>
            Qualidade
          </b>
        </h2>
        <p>
          Investir na qualidade dos produtos e serviços prestados é investir no futuro da empresa como referencia no
          mercado, é por isso que a SysRS investe em rigorosos padrões de qualidade no desenvolvimento de seus
          produtos e atende cada cliente como se ele fosse único, pois para nós, cada cliente é único.
        </p>

        <h2 className="topic">
          <b>
            Política de qualidade
          </b>
        </h2>
        <ul className={`list ${s.List}`}>
          <li>
            Desenvolver softwares que atendam 100% das necessidades dos nossos clientes.
          </li>
          <li>
            Buscar o aprimoramento e a evolução constante de nossos produtos e serviços.
          </li>
          <li>
            Honrar os prazos contratados juntos aos nossos clientes e parceiros.
          </li>
          <li>
            Transformar as necessidades dos nossos clientes em soluções eficazes, desenvolvendo-as de forma rápida,
            profissional e responsável.
          </li>
        </ul>
      </Paper>
    </Layout>
  )
}
