import * as s from './styles/paper.module.scss';
import React, { ReactElement } from 'react';

interface Props {
  title: string
  children: React.ReactNode
}

export default function Paper({ title, children }: Props): ReactElement {
  return (
    <section className={s.Paper_section}>
      <h1 className="title u-margin-bottom">{title}</h1>
      <div className={s.Paper}>
        {children}
      </div>
    </section >
  )
}
